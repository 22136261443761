<script>
const sidebarMenu = [
  //{ path: '/', icon: 'fa fa-sitemap', title: 'Home' },
  { path: '/lotesciclos', icon: 'ion-ios-gift bg-gradient-yellow-orange', title: 'Montar Carga'},
   { path: '/cadastromateriais', icon: 'ion-ios-cart bg-gradient-red-pink ', title: 'Materiais'},
   // { path: '/loteitem', icon: 'ion-ios-pulse bg-gradient-blue', title: 'Itens de Lotes'},
    { path: '/monitoramento', icon: 'ion-ios-pulse bg-gradient-blue', title: 'Monitoramento'},
    //{ path: '/loteteste', icon: 'ion-ios-pulse bg-gradient-blue', title: 'Teste'},
    //{ path: '/lotes1', icon: 'fa fa-align-left', title: 'Gerencial'},
  /*{ path: '/menu', icon: 'fa fa-align-left', title: 'Cadastros',
    children: [
      { path: '/cadastromateriais', title: 'Materiais' },
      { path: '/menu/menu-1-3', title: 'Embalagens' },
    ]
  },*/
]

export default sidebarMenu;
</script>
