import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api2.dataciclos.com.br'
    //baseURL: 'http://localhost:3001'

})



export default api;

