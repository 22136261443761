<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:;">Criar Lote</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-12">
        <!-- BEGIN panel -->
        <panel title="Lote" noButton="true">
          <template slot="header">
            <h4 class="panel-title">Lote</h4>
            <div v-show="loading" class="progress h-10px bg-gray-700 w-150px">
              <div
                class="
                  progress-bar progress-bar-striped
                  bg-success
                  progress-bar-animated
                "
                style="width: 100%"
              >
                AGUARDE...
              </div>
            </div>
          </template>

          <div v-show="!loading">
            <form
              class="row row-cols-lg-auto g-3 align-items-center"
              action="/"
              method="POST"
            >
              <div class="w-25">
                <label class="form-label w-50">Equipamento</label>
                <v-select
                  class="w-100"
                  :options="equipamentos"
                  label="descricao"
                  v-model="equipSelecionado"
                  :reduce="(equipamentos) => equipamentos.codigo"
                  :disabled="desabilita"
                ></v-select>
              </div>
              <div class="w-25">
                <label class="form-label w-50">Programa</label>
                <v-select
                  class="w-100"
                  :options="programas"
                  label="descricao"
                  v-model="programaSelecionado"
                  :reduce="(programas) => programas.codigo"
                  :disabled="desabilita"
                ></v-select>
              </div>
              <div v-show="mostraLote" class="w-25">
                <label v-show="mostraLote" class="form-label w-100">Lote</label>
                <input
                  @blur="validaLote1"
                  v-show="mostraLote"
                  type="text"
                  class="form-control"
                  id="lote"
                  placeholder="Lote"
                  v-model="lote"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :disabled="desabilita"
                />
              </div>
              <div v-show="mostraIntegrador" class="w-25">
                <label v-show="mostraIntegrador" class="form-label w-100"
                  >Integrador</label
                >
                <input
                  v-show="mostraIntegrador"
                  type="text"
                  class="form-control"
                  id="integrador"
                  placeholder="Integrador"
                  v-model="integrador"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :disabled="desabilita"
                />
              </div>

              <div v-show="mostraBiologico" class="w-25">
                <label v-show="mostraBiologico" class="form-label w-100"
                  >Número Biológico</label
                >
                <input
                  v-show="mostraBiologico"
                  type="text"
                  class="form-control"
                  id="numeroBiologico"
                  placeholder="Número Biológico"
                  v-model="numeroBiologico"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :disabled="desabilita"
                />
              </div>
              <div v-show="mostraProduto" class="w-25">
                <label v-show="mostraProduto" class="form-label w-50"
                  >Produto</label
                >
                <v-select
                  class="w-100"
                  :options="produto"
                  label="descricao"
                  :reduce="(produto) => produto.id"
                  v-model="produtoSelecionado"
                  :disabled="desabilita"
                ></v-select>
              </div>
            </form>
          </div>
          <br />
          <panel title="Materiais" v-show="!loading" noButton="true">
            <div>
              <form class="row row-cols-lg-auto g-3 align-items-center">
                <div class="w-100" v-show="horas_alteracao > -5">
                  <label class="form-label w-100">Material</label>
                  <v-select
                    class="w-100"
                    :options="materiais"
                    label="descricao"
                    v-model="materialSelecionado"
                  ></v-select>
                </div>
                <div class="w-20" v-show="horas_alteracao > -5">
                  <label class="form-label w-100">Quantidade</label>
                  <input
                    type="text"
                    class="form-control"
                    id="quantidade"
                    placeholder="Quantidade"
                    v-model="quantidade"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </div>
                <div class="w-25" v-show="horas_alteracao > -5">
                  <label class="form-label w-20">Embalagem</label>
                  <v-select
                    class="w-100"
                    v-model="embalagemSelecionado"
                    :options="embalagens"
                    :reduce="(embalagens) => embalagens.codigo"
                    label="descricao"
                  ></v-select>
                </div>
                <div class="w-20" v-show="horas_alteracao > -5">
                  <label class="form-label w-100">Validade</label>
                  <input
                    type="date"
                    class="form-control"
                    id="validade"
                    v-model="validade"
                    oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  />
                </div>
                <div class="w-25" v-show="horas_alteracao > -5">
                  <label class="form-label w-100">Origem</label>
                  <v-select
                    class="w-100"
                    :options="origem"
                    label="descricao"
                    v-model="origemSelecionada"
                    :reduce="(origem) => origem.codigo"
                  ></v-select>
                </div>
                <div class="w-75" v-show="horas_alteracao > -5">
                  <label class="form-label w-100">Observação</label>
                  <input
                    type="text"
                    class="form-control"
                    id="observacao"
                    placeholder="Observação"
                    v-model="observacao"
                    oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  />
                </div>

                <div class="ms-auto" v-show="horas_alteracao > -5">
                  <a
                    @click="validar"
                    class="btn btn-success btn-rounded px-4 rounded-pill"
                    ><i
                      class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"
                    ></i>
                    Adicionar Materiais</a
                  >
                </div>
              </form>
              <vue-good-table
                class="w-100"
                :columns="columns"
                ref="teste"
                :rows="item"
                :lineNumbers="true"
                :search-options="{
                  enabled: true,
                  placeholder: 'Pesquisar',
                }"
                :pagination-options="{ enabled: true, position: 'bottom' }"
                :selectOptions="{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionInfoClass:
                    'alert alert-info m-b-0 no-rounded-corner',
                  selectionText: 'registros selecionados',
                  clearSelectionText: 'Limpar',
                }"
              >
                <div slot="selected-row-actions">
                  <button @click="download" class="btn btn-xs btn-primary me-2">
                    Imprimir Etiquetas
                  </button>
                  <button
                    v-show="horas_alteracao > -5"
                    @click="
                      notificacaoExcluirItem(
                        'danger',
                        'Atenção! Deseja excluir os itens selecionados?',
                        'Excluir Itens?'
                      )
                    "
                    class="btn btn-xs btn-danger"
                  >
                    Excluir
                  </button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span
                    v-if="
                      props.column.field == '1' && props.row.quantidade == 15
                    "
                  >
                    <span style="font-weight: bold; color: red">{{
                      props.row.quantidade
                    }}</span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </panel>

          <!-- END hljs-wrapper -->
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
    </div>

    <div class="row" v-show="!loading">
      <!-- BEGIN col-12 -->
      <div class="col-24">
        <div class="ms-auto">
          <a
            v-show="!this.$store.state.modoEdicao"
            @click="
              swalNotificationCancel(
                'question',
                'Deseja Incluir o Lote?',
                'Incluir Lote?'
              )
            "
            class="btn btn-success btn-rounded px-4 rounded-pill"
            ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
            Concluir Lote</a
          >

          <a
            @click="voltar()"
            class="btn btn-danger btn-rounded px-4 rounded-pill"
            ><i class="fa fa-lg me-2 ms-n2 text-success-900"></i> Voltar</a
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";
import FileSaver from "file-saver";

export default {
  name: "CriarLote",
  data() {
    return {
      horas_alteracao: 0,
      desabilita: false,
      mostraLote: false,
      mostraIntegrador: false,
      mostraBiologico: false,
      mostraProduto: false,
      validaLote: false,
      validaItegrador: false,
      validaBiologico: false,
      validaProduto: false,
      equipSelecionado: "",
      loading: false,
      loteValidado: false,
      equipDados: [],
      materialSelecionado: [],
      embalagemSelecionado: "",
      programaSelecionado: "",
      alterou: false,
      botaoIncluir: false,
      loteok: true,
      loteInterno: 0,
      teste: [],
      lote: "",
      integrador: "",
      numeroBiologico: "",
      equipamentos: [],
      tipoEtiqueta: "",
      programas: [],
      produtoSelecionado: "",
      produto: [
        {
          id: "1                   ",
          descricao: "ACIDO PERESAL",
        },
        {
          id: "2                   ",
          descricao: "PRODUTO 2",
        },
        {
          id: "3                   ",
          descricao: "PRODUTO 3",
        },
      ],
      capa: [],
      item: [],
      materiais: [],
      embalagens: [],
      addMat: [],
      quantidade: "",
      validade: "",
      origem: [
        { codigo: "P", descricao: "PROPRIO" },
        { codigo: "T", descricao: "TERCEIROS" },
      ],
      origemSelecionada: "",
      observacao: "",
      xcodigoEmbalagem: "",
      xdescEmbalagem: "",
      columns: [
        {
          label: "Material",
          field: "descricao_material",
          //width: "20%",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Quant",
          field: "quantidade",
          type: "number",
          width: "5%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Embalagem",
          field: "descricao_embalagem",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Validade",
          field: "data_validade",
          type: "date",
          dateInputFormat: "yyyy-mm-dd",
          dateOutputFormat: "dd-mm-yyyy",
          width: "10%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Obs",
          field: "observacao",
          //type: 'percentage',
          width: "20%",
        },
        {
          label: "Ori",
          field: "origem",
          //type: 'percentage',
          width: "2%",
          tdClass: "text-center valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Recno",
          field: "recno",
          //type: 'number',
          width: "10%",
          hidden: true,

          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Lote Material",
          field: "lote_material",
          //type: 'number',
          width: "10%",
          hidden: false,

          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
      ],
    };
  },
  created() {
    AppOptions.appEmpty = false;

    if (this.$store.state.modoEdicao) {
      this.carregaEdicao();
    } else {
      this.carregaNovo();
    }
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;

    if (!this.$store.state.modoEdicao && this.botaoIncluir) {
      this.swalNotificationSair(
        "danger",
        "Deseja sair sem concluir o lote?",
        "Atenção",
        to,
        from,
        next
      );
    } else {
      next();
    }
  },
  methods: {
    async carregaNovo() {
      this.loading = true;
      await this.carregaEquipamento();
      await this.carregaPrograma();
      await this.carregaMateriais();
      await this.carregaEmbalagem();
      await this.getLoteInterno();
      this.loading = false;
    },
    async carregaEdicao() {
      this.loading = true;
      this.loteInterno = this.$store.state.loteInterno;

      await this.carregaEquipamento();
      await this.carregaMateriais();
      await this.carregaEmbalagem();
      await this.carregaPrograma();
      await this.carregaCapa();
      await this.carregaItem();

      this.loading = false;
    },
    download() {
      //console.log(this.$refs["teste"].selectedRows);

      let teste = "";
      let lok = false;

      for (var i = 0; i < this.$refs["teste"].selectedRows.length; i++) {
        lok = true;

        let a = this.$refs["teste"].selectedRows[i];

        //console.log("qual o recno ????");
        //console.log(a.recno);
        // Iterate over numeric indexes from 0 to 5, as everyone expects.
        //console.log(a.descMaterial);

        let current_datetime = new Date();
        current_datetime.setDate(current_datetime.getDate());

        //current_datetime.setDate(current_datetime.getDate() + this.validade);

        let mes = current_datetime.getMonth() + 1;

        if (mes < 10) {
          mes = "0" + mes;
        }

        let dia = current_datetime.getDate();

        if (dia < 10) {
          dia = "0" + dia;
        }

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date =
          dia + "/" + mes + "/" + current_datetime.getFullYear();

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date2 =
          a.data_validade.substring(8, 10) +
          "/" +
          a.data_validade.substring(5, 7) +
          "/" +
          a.data_validade.substring(0, 4);

        //this.$refs['teste'].unselectAllInternal();

        let apoioOrigem = "";

        //console.log("tipo etiqueta");
        //console.log(this.equipSelecionado.tipo_etiqueta);

        if (a.origem.trim() == "P") {
          apoioOrigem = "PROPRIO";
        } else {
          apoioOrigem = "TERCEIROS";
        }

        if (this.$store.state.codigoEmpresa.trim() != "3") {
          let key2 = this.programaSelecionado;
          let filtro2 = this.programas.filter((item) => {
            return item.codigo == key2;
          });

          //console.log(this.programas);

          //console.log("Programa Selecionado");
          //console.log(filtro2[0].descricao);

          let key = this.equipSelecionado;
          let filtro1 = this.equipamentos.filter((item) => {
            return item.codigo == key;
          });

          //console.log("descricao equipamento");
          //console.log(filtro1[0].descricao);

          let nLinha = 20;
          let cMaterial = a.descricao_material
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim();

          let cMat1 = cMaterial.substring(0, 29).trim();
          //console.log(cMaterial);
          let cMat2 = cMaterial.substring(29, 69).trim();
          //console.log(cMaterial);
          let cMat3 = cMaterial.substring(69, 99).trim();
          
          teste += "N\r\n";
          teste += "D9\r\n";
          teste += "S3\r\n";
          teste += "JF\r\n";
          teste += "ZT\r\n";
          teste += "Q280, 24\r\n";
          teste += "q560\r\n";
          teste += `A20,20,0,3,1,1,N,"` + cMat1 + `"\r\n`;

          nLinha += 20;

          if (cMat2.length > 0) {
            teste +=
              `A20,` + parseInt(nLinha) + `,0,3,1,1,N,"` + cMat2 + `"\r\n`;

            nLinha += 20;
          }

          if (cMat3.length > 0) {
            teste +=
              `A20,` + parseInt(nLinha) + `,0,3,1,1,N,"` + cMat3 + `"\r\n`;

            nLinha += 20;
          }

          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Data: ` +
            formatted_date.trim() +
            ` Val: ` +
            formatted_date2.trim() +
            `"\r\n`;

            nLinha += 20;


          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Equipamento: ` +
            filtro1[0].descricao.trim() +
            `"\r\n`;
            nLinha += 20;

          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Prog: ` +
            filtro2[0].descricao.trim() +
            `"\r\n`;
            nLinha += 20;

          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Lote: ` +
            this.lote.trim() +
            `"\r\n`;
            nLinha += 20;
          teste += 
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Resp: ` +
            this.$store.state.nomeUsuario.trim().toUpperCase() +
            `"\r\n`;
            nLinha += 20;
          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Origem: ` +
            apoioOrigem +
            `"\r\n`;
            nLinha += 20;
          teste +=
            `A20,` +
            parseInt(nLinha) +
            `,0,3,1,1,N,"Obs: ` +
            a.observacao.trim() +
            `"\r\n`;

            teste += `B100,200,0,1,2,3,60,N,"` + a.lote_material + `"` + `\r\n`;

          teste += `P` + parseInt(a.quantidade) + `\r\n`;
        } else {
          //console.log("entrou no else.........etiqueta");

          if (this.tipoEtiqueta.trim() == "1") {
            //console.log("entrou no 1");

            let key = this.equipSelecionado;
            let filtro1 = this.equipamentos.filter((item) => {
              return item.codigo == key;
            });

            let key2 = this.programaSelecionado;
            let filtro2 = this.programas.filter((item) => {
              return item.codigo == key2;
            });

            ("use strict");
            teste += `<xpml><page quantity='0' pitch='30.0 mm'></xpml>n\r\n`;
            teste += `M0500\r\n`;
            teste += `O0220\r\n`;
            teste += `V0\r\n`;
            teste += `f220\r\n`;
            teste += `SE\r\n`;
            teste += `D\r\n`;
            teste += `<xpml></page></xpml><xpml><page quantity='3' pitch='30.0 mm'></xpml>\u0002L\r\n`;
            teste += `D11\r\n`;
            teste += `PE\r\n`;
            teste += `A2\r\n`;
            teste +=
              `102200001050019MAT: ` +
              a.descricao_material
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim() +
              `\r\n`;
            teste += `102200000930019DATA: ` + formatted_date.trim() + `\r\n`;
            teste += `102200000810019VAL: ` + formatted_date2.trim() + `\r\n`;
            teste +=
              `102200000680019EQUIPAMENTO: ` +
              filtro1[0].descricao.trim() +
              `\r\n`;
            teste +=
              `102200000560019PROG: ` + filtro2[0].descricao.trim() + `\r\n`;
            teste += `102200000440019LOTE:` + this.lote.trim() + `\r\n`;
            teste +=
              `102200000320019RESP: ` +
              this.$store.state.nomeUsuario.trim().toUpperCase() +
              `\r\n`;
            teste += `102200000190019ORIGEM:` + apoioOrigem + `\r\n`;
            teste += `102200000070019OBS: ` + a.observacao.trim() + `\r\n`;
            teste += `Q` + parseInt(a.quantidade) + `\r\n`;
            teste += `E\r\n`;
            teste += `<xpml></page></xpml><xpml><end/></xpml>\r\n`;
          } else if (this.tipoEtiqueta.trim() == "2") {
            let key = this.equipSelecionado;
            let filtro1 = this.equipamentos.filter((item) => {
              return item.codigo == key;
            });


            let key2 = this.produtoSelecionado;
            let filtro2 = this.produto.filter((item) => {
              return item.id == key2;
            });


            //console.log("entrou no 2");
            //OPERACAO MANUAL
            ("use strict");
            teste += `<xpml><page quantity='0' pitch='30.0 mm'></xpml>n\r\n`;
            teste += `M0500\r\n`;
            teste += `O0220\r\n`;
            teste += `V0\r\n`;
            teste += `f220\r\n`;
            teste += `SE\r\n`;
            teste += `D\r\n`;
            teste += `<xpml></page></xpml><xpml><page quantity='3' pitch='30.0 mm'></xpml>\u0002L\r\n`;
            teste += `D11\r\n`;
            teste += `PE\r\n`;
            teste += `A2\r\n`;
            teste +=
              `102200001050019MAT: ` +
              a.descricao_material
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim() +
              `\r\n`;
            teste += `102200000930019DATA: ` + formatted_date.trim() + `\r\n`;
            teste += `102200000810019VAL: ` + formatted_date2.trim() + `\r\n`;
            teste +=
              `102200000680019EQUIPAMENTO: ` +
              filtro1[0].descricao.trim() +
              `\r\n`;
            teste +=
              `102200000560019PRODUTO: ` + filtro2[0].descricao.trim() + `\r\n`;
            teste +=
              `102200000440019RESP: ` +
              this.$store.state.nomeUsuario
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim()
                .toUpperCase() +
              `\r\n`;
            teste += `102200000320019ORIGEM:` + apoioOrigem + `\r\n`;
            `\r\n`;
            teste +=
              `102200000190019OBS: ` +
              a.observacao
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim() +
              `\r\n`;
            teste += `Q` + parseInt(a.quantidade) + `\r\n`;
            teste += `E\r\n`;
            teste += `<xpml></page></xpml><xpml><end/></xpml>\r\n`;
          } else if (this.tipoEtiqueta.trim() == "3") {
            //console.log("entrou no 3");
            //DESINFECCAO

            let key = this.equipSelecionado;
            let filtro1 = this.equipamentos.filter((item) => {
              return item.codigo == key;
            });


            let key2 = this.programaSelecionado;
            let filtro2 = this.programas.filter((item) => {
              return item.codigo == key2;
            });


            ("use strict");
            teste += `<xpml><page quantity='0' pitch='30.0 mm'></xpml>n\r\n`;
            teste += `M0500\r\n`;
            teste += `O0220\r\n`;
            teste += `V0\r\n`;
            teste += `f220\r\n`;
            teste += `SE\r\n`;
            teste += `D\r\n`;
            teste += `<xpml></page></xpml><xpml><page quantity='3' pitch='30.0 mm'></xpml>\u0002L\r\n`;
            teste += `D11\r\n`;
            teste += `PE\r\n`;
            teste += `A2\r\n`;
            teste +=
              `102200001050019MAT: ` +
              a.descricao_material
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim() +
              `\r\n`;
            teste += `102200000930019DATA: ` + formatted_date.trim() + `\r\n`;
            teste += `102200000810019VAL: ` + formatted_date2.trim() + `\r\n`;
            teste +=
              `102200000680019EQUIPAMENTO: ` +
              filtro1[0].descricao.trim() +
              `\r\n`;
            teste +=
              `102200000560019PROG: ` + filtro2[0].descricao.trim() + `\r\n`;
            teste +=
              `102200000440019RESP: ` +
              this.$store.state.nomeUsuario.trim().toUpperCase() +
              `\r\n`;
            teste += `102200000320019ORIGEM:` + apoioOrigem + `\r\n`;
            `\r\n`;
            teste +=
              `102200000190019OBS: ` +
              a.observacao
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .trim() +
              `\r\n`;
            teste += `Q` + parseInt(a.quantidade) + `\r\n`;
            teste += `E\r\n`;
            teste += `<xpml></page></xpml><xpml><end/></xpml>\r\n`;
          }
        }
      }

      if (lok) {
        var blob = new Blob([teste], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "etiqueta_mca.txt");

        this.$refs["teste"].unselectAllInternal();
      }
    },

    async incluirLote() {
      //console.log(this.$refs['teste'].selectedRows);

      //this.$refs['teste'].selectedRows
      //this.$refs['teste'].unselectAllInternal();

      if (this.item.length == 0) {
        this.swalNotification(
          "error",
          "Não é possível incluir lotes sem materiais. Verifique!",
          "Atenção!"
        );
        return;
      }

      let current_datetime = new Date();
      current_datetime.setDate(current_datetime.getDate());

      //console.log(current_datetime);

      //current_datetime.setDate(current_datetime.getDate() + this.validade);

      let mes = current_datetime.getMonth() + 1;

      if (mes < 10) {
        mes = "0" + mes;
      }

      let dia = current_datetime.getDate();

      if (dia < 10) {
        dia = "0" + dia;
      }

      //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
      let formatted_date =
        current_datetime.getFullYear() + "-" + mes + "-" + dia;
      //console.log(formatted_date);

      let Lotes = {
        empresa: this.$store.state.codigoEmpresa,
        equipamento: this.equipSelecionado,
        lote: this.lote,
        integrador: this.integrador,
        biologico: this.numeroBiologico,
        programa: this.programaSelecionado,
        produto: this.produtoSelecionado,
        data: formatted_date,
        usuario: this.$store.state.login,
        lote_interno: parseInt(this.loteInterno),
      };

      const response = await api.post("inclotecapa2", Lotes);

      if (response.status == 200) {
        //-this.embalagens = response.data;
        //console.log("inclui lote certo");
        this.botaoIncluir = false;
        this.voltar();
      } else {
        //console.log("erro inclusao lote");
      }
    },
    async gravarItem(Lotes) {
      const response = await api.post("incloteitens", Lotes);

      if (response.status == 200) {
        //-this.embalagens = response.data;

        console.log("inclui  item  certo");
      } else {
        console.log("erro inclusao item lote");
      }

      this.carregaItem();
    },
    validar: function (e) {
      e.preventDefault();

      if (this.equipSelecionado == "") {
        this.swalNotification("error", "Informe o equipamento!", "Atenção!");
      } else if (this.programaSelecionado == "") {
        this.swalNotification("error", "Informe o Programa!", "Atenção!");
      } else if (this.lote == "" && this.validaLote) {
        this.swalNotification("error", "Informe o Lote!", "Atenção!");
      } else if (!this.loteok && this.validaLote) {
        this.swalNotification("error", "Lote já cadastrado!", "Atenção!");
      } else if (this.produtoSelecionado == "" && this.validaProduto) {
        this.swalNotification("error", "Informe o Produto!", "Atenção!");
      } else if (this.integrador == "" && this.validaIntegrador) {
        this.swalNotification("error", "Informe o Integrador!", "Atenção!");
      } else if (this.numeroBiologico == "" && this.validaBiologico) {
        this.swalNotification(
          "error",
          "Informe o Número Biológico!",
          "Atenção!"
        );
      } else if (this.produtoSelecionado == "" && this.validaProduto) {
        this.swalNotification("error", "Informe o Produto!", "Atenção!");
      } else if (this.materialSelecionado == "") {
        this.swalNotification("error", "Informe o Material!", "Atenção!");
      } else if (this.quantidade == 0 || this.quantidade == "") {
        this.swalNotification("error", "Informe a Quantidade!", "Atenção!");
      } else if (this.validade == "") {
        this.swalNotification(
          "error",
          "Informe a Data de Validade!",
          "Atenção!"
        );
      } else {
        let current_datetime = new Date();
        current_datetime.setDate(current_datetime.getDate());

        //console.log(current_datetime);

        //current_datetime.setDate(current_datetime.getDate() + this.validade);

        let mes = current_datetime.getMonth() + 1;

        if (mes < 10) {
          mes = "0" + mes;
        }

        let dia = current_datetime.getDate();

        if (dia < 10) {
          dia = "0" + dia;
        }

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date =
          current_datetime.getFullYear() + "-" + mes + "-" + dia;
        //console.log(formatted_date);

        let Lotes = {
          empresa: this.$store.state.codigoEmpresa,
          codigoMaterial: this.materialSelecionado.codigo,
          lote: this.lote,
          data: formatted_date,
          codigoEmbalagem: this.embalagemSelecionado,
          validade: this.validade,
          observacao: this.observacao,
          codigoOrigem: this.origemSelecionada,
          equipamento: this.equipSelecionado,
          quantidade: parseInt(this.quantidade),
          usuario: this.$store.state.login,
          lote_interno: this.loteInterno,
        };

        
        this.gravarItem(Lotes);

        //console.log("adicionou produtos");
        //console.log(this.addMat);

        this.botaoIncluir = true;
        this.materialSelecionado = "";
        this.validade = "";
        this.embalagemSelecionado = "";
        this.quantidade = "";
        this.observacao = "";
      }

      /*

      

      

      if (this.equipSelecionado == "") {
        this.swalNotification("error", "Informe o equipamento!", "Atenção!");
      } else if (this.lote == "") {
        this.swalNotification("error", "Informe o Lote!", "Atenção!");
      }*/
    },
    voltar() {
      this.$router.push({ path: "/lotesciclos" });
    },
    deleteItem() {
      //alert('entrou no delete - ' + key);

      for (var i = 0; i < this.$refs["teste"].selectedRows.length; i++) {
        let a = this.$refs["teste"].selectedRows[i];

        /*filtro = this.addMat.filter((item) => {
          return item.key !== a.key;
        });*/

        this.deleteApi(a.recno);
      }
    },
    validaLote1() {
      console.log("Entrou no evento valida lote");
      if (this.validaLote) {
        this.validaLote2();
      }
    },
    async validaLote2() {
      const response = await api.post("validalote", {
        empresa: this.$store.state.codigoEmpresa,
        lote: this.lote,
      });

      

      if (response.status == 200) {
        if (response.data.status == "1") {
          this.loteok = true;
        } else {
          this.loteok = false;
          this.swalNotification("error", "Lote já cadastrado!", "Atenção!");
        }
      } else {
        this.loteok = false;
      }
    },
    async deleteApi(recno) {
      const response = await api.post("excluiritenslote", {
        recno: parseInt(recno),
        usuario: this.$store.state.login,
      });

      if (response.status == 200) {
        this.carregaItem();
      } else {
        console.log("erro deletar item............");
      }
    },
    async carregaEquipamento() {
      const response = await api.post("equipamentos", {
        empresa: this.$store.state.codigoEmpresa,
      });

      
      if (response.status == 200) {
        this.equipamentos = response.data;
      } else {
        this.equipamentos = [];
      }
    },
    async getLoteInterno() {
      const response = await api.post("loteinterno", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.loteInterno = parseInt(response.data.lote_interno);
      } else {
        this.loteInterno = 0;
      }

    },
    async carregaPrograma() {
      const response = await api.post("programas", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.programas = response.data;
      } else {
        this.programas = [];
      }
    },
    async carregaMateriais() {
      const response = await api.post("materiais", {
        empresa: this.$store.state.codigoEmpresa,
      });

      console.log("carrega materiais????????");

      if (response.status == 200) {
        this.materiais = response.data;
      } else {
        this.materiais = [];
      }
    },
    async carregaEmbalagem() {
      const response = await api.post("embalagem", {
        empresa: this.$store.state.codigoEmpresa,
      });


      if (response.status == 200) {
        this.embalagens = response.data;
      } else {
        this.embalagens = [];
      }
    },
    async carregaCapa() {
      const response = await api.post("lotecapa2", {
        empresa: this.$store.state.codigoEmpresa,
        lote_interno: parseInt(this.$store.state.loteInterno),
      });


      if (response.status == 200) {
        console.log(response.data);
        this.capa = response.data;
      } else {
        this.capa = [];
      }

      this.programaSelecionado = this.capa.programa;
      this.produtoSelecionado = this.capa.produto;
      this.equipSelecionado = this.capa.equipamento;
      this.lote = this.capa.lote;
      this.integrador = this.capa.integrador;
      this.numeroBiologico = this.capa.biologico;
      this.lote_interno = this.capa.lote_interno;
      this.horas_alteracao = this.capa.horas_alteracao;

    },
    async carregaItem() {
      const response = await api.post("loteitem2", {
        empresa: this.$store.state.codigoEmpresa,
        lote_interno: parseInt(this.loteInterno),
      });


      if (response.status == 200) {
        console.log(response.data);
        this.item = response.data;
      } else {
        this.item = [];
      }

    },
    swalNotification(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: false,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
    swalNotificationCancel(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          this.incluirLote();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
    swalNotificationSair(swalType, msg, titulo, to, from, next) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          next();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
    notificacaoExcluirItem(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          this.deleteItem();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
  },
  watch: {
    embalagemSelecionado: {
      deep: true,
      handler() {
        let filtro = this.embalagens.filter((codigo) => {
          return codigo.codigo == this.embalagemSelecionado;
        });

        let current_datetime = new Date();
        current_datetime.setDate(
          current_datetime.getDate() + filtro[0].validade
        );

        //console.log(current_datetime);

        //current_datetime.setDate(current_datetime.getDate() + this.validade);

        let mes = current_datetime.getMonth() + 1;

        if (mes < 10) {
          mes = "0" + mes;
        }

        let dia = current_datetime.getDate();

        //console.log("dia????");
        //console.log(dia.toString);

        if (dia < 10) {
          dia = "0" + dia;
        }

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date =
          current_datetime.getFullYear() + "-" + mes + "-" + dia;
        //console.log(formatted_date);

        //d.setDate(d.getDate() + days);

        this.xcodigoEmbalagem = filtro[0].codigo;
        this.xdescEmbalagem = filtro[0].descricao;
        this.validade = formatted_date;
      },
    },
    materialSelecionado: {
      deep: true,
      handler() {
        this.embalagemSelecionado = this.materialSelecionado.embalagem;
        this.origemSelecionada = "P";
      },
    },
    item: {
      deep: true,
      handler() {
        if (this.item.length > 0) {
          this.desabilita = true;
        } else {
          this.desabilita = false;
        }

      },
    },
    equipSelecionado: {
      deep: true,
      handler() {
        console.log("equipamento selecionado>???/");
        console.log(this.equipSelecionado);

        let key = this.equipSelecionado;
        let filtro = this.equipamentos.filter((item) => {
          return item.codigo == key;
        });

        this.equipDados = filtro;



        //mostrar campos cabecalho
        if (filtro[0].lote.substring(0, 1) == "S") {
          this.mostraLote = true;
        } else {
          this.mostraLote = false;
        }

        if (filtro[0].integrador.substring(0, 1) == "S") {
          this.mostraIntegrador = true;
        } else {
          this.mostraIntegrador = false;
        }

        if (filtro[0].biologico.substring(0, 1) == "S") {
          this.mostraBiologico = true;
        } else {
          this.mostraBiologico = false;
        }

        if (filtro[0].produto.substring(0, 1) == "S") {
          this.mostraProduto = true;
        } else {
          this.mostraProduto = false;
        }

        //valida campos obrigatorios

        if (filtro[0].lote.substring(1, 2) == "S") {
          this.validaLote = true;
        } else {
          this.validaLote = false;
        }

        if (filtro[0].integrador.substring(1, 2) == "S") {
          this.validaIntegrador = true;
        } else {
          this.validaIntegrador = false;
        }

        if (filtro[0].biologico.substring(1, 2) == "S") {
          this.validaBiologico = true;
        } else {
          this.validaBiologico = false;
        }

        if (filtro[0].produto.substring(1, 2) == "S") {
          this.validaProduto = true;
        } else {
          this.validaProduto = false;
        }

        this.tipoEtiqueta = filtro[0].tipo_etiqueta;



        /*this.lote = "" ; 
        this.produtoSelecionado = "" ; 
        this.programaSelecionado = "" ; 
        this.numeroBiologico = "" ; 
        this.integrador = "" ; */

      },
    },
    lote: {
      deep: true,
      handler() {
        console.log("Alterou lote??");
        console.log(this.lote);
      },
    },
  },
};
</script>