var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('div',[_vm._m(0),_c('h1',{staticClass:"page-header mb-0"},[_vm._v("Montar Carga")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.expira),expression:"expira"}],staticClass:"fs-5 fw-bold text-red-600 mb-0"},[_vm._v("Atenção! Acesso expira em 21/07/2022! Verifique com seu gerente de contas! ")])]),_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"btn btn-yellow btn-rounded px-4 rounded-pill",on:{"click":_vm.gerencial}},[_c('i',{staticClass:"fa fa-plus fa-lg me-2 ms-n2 text-success-900"}),_vm._v("Gerencial")]),_c('a',{staticClass:"btn btn-success btn-rounded px-4 rounded-pill",on:{"click":_vm.criarLote}},[_c('i',{staticClass:"fa fa-plus fa-lg me-2 ms-n2 text-success-900"}),_vm._v(" Criar Lote")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('panel',{attrs:{"title":"Lotes X Ciclos"}},[_c('div',[_c('form',{staticClass:"row row-cols-lg-auto g-3 align-items-center"}),_c('vue-good-table',{ref:"teste",staticClass:"w-100",attrs:{"columns":_vm.columns,"rows":_vm.capa,"lineNumbers":false,"search-options":{
              enabled: true,
              placeholder: 'Pesquisar',
            },"pagination-options":{ enabled: true, position: 'bottom' },"selectOptions":{
              enabled: false,
              selectOnCheckboxOnly: false,
              selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
              selectionText: 'registros selecionados',
              clearSelectionText: 'Limpar',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'after')?_c('span',[_c('button',{on:{"click":function($event){return _vm.editRow(
                      props.row.lote,
                      props.row.equipamento,
                      props.row.lote_interno
                    )}}},[_vm._v(" Editar ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"})])],1)])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item active"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Montar Carga")])])])}]

export { render, staticRenderFns }