<template>
  <panel noButton="true" v-bind:title="equipamento + ' - ' + programa">
    <template slot="header">
      <h4 class="panel-title">{{ equipamento }} - {{ programa }}</h4>
      <div class="progress h-20px bg-gray-700 w-80px">
        <div
          v-bind:class="classe"
          style="width: 100%"
        ></div>
      </div>
    </template>

    <vue-custom-scrollbar style="height: 280px">
      <ul>
        <li v-for="apoio in capa.item" :key="apoio.key">{{ apoio.dados }}</li>
      </ul>
    </vue-custom-scrollbar>
  </panel>
</template>


<script>
import AppOptions from "../../config/AppOptions.vue";
import api from "../../services/api";

export default {
  name: "Caixa",
  props: ["equipamento"],
  created() {
    AppOptions.appEmpty = false;
    this.carregaLeitura();
    this.timer = setInterval(this.carregaLeitura, 10000);
  },
  data() {
    return {
      capa: [],
      programa: "",
      prog_status: "",
      cor_janela: "bg-danger-700 text-white",
      cor_titulo: "danger",
      classe: "",
    };
  },
  methods: {
    async carregaLeitura() {
      console.log(this.equipamento);
      console.log("entrou no carrega leitura");
      const response = await api.post("leitura", {
        empresa: this.$store.state.codigoEmpresa.trim(),
        equipamento: this.equipamento.trim(),
      });

      console.log(response.data);

      console.log(" qual statusss???") ; 
      //console.lot(this.capa.capa[0].status);

      if (response.status == 200) {
        this.capa = response.data;
        this.programa = this.capa.capa[0].programa;
        this.prog_status = this.capa.capa[0].status;


        console.log(this.prog_status );



        //this.prog_status = "C" ; //this.capa.capa[0].status;

        if (this.prog_status == "C") {
          this.classe = "progress-bar progress-bar-striped bg-green progress-bar-animated";

        } else if (this.prog_status == "A") {
          this.classe = "progress-bar progress-bar-striped bg-warning progress-bar-animated";
        } else {
          this.classe = "progress-bar progress-bar-striped bg-danger progress-bar-animated";
        }
      } else {
        this.capa = [];
        this.programa = "";
        this.prog_status = "";
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>


<style scoped>
ul {
  list-style-type: none;
}
</style>


