<script>
//import Home from '../pages/Home.vue'
import Login from '../pages/Login.vue'
import LotesCiclos from '../pages/LotesCiclos.vue'
import CriarLote from '../pages/CriarLote.vue'
import CriarLote2 from '../pages/CriarLote2.vue'
import CadastroMateriais from '../pages/CadastroMateriais.vue'
import ConsultaMateriais from '../pages/ConsultaMateriais.vue'
import LoteItem from '../pages/LoteItem.vue'
import Lotes1 from '../pages/Lotes1.vue'
import Monitoramento from '../pages/Monitoramento.vue'



const routes = [
  { path: '/', component: LotesCiclos , meta: {
    requiresAuth: true,

  }},
  { path: '/lotesciclos', component: LotesCiclos , meta: {
    requiresAuth: true,

  }},
  { path: '/criarlote', component: CriarLote , meta: {
    requiresAuth: true,

  }},
  { path: '/criarlote2', component: CriarLote2 , meta: {
    requiresAuth: true,

  }},
  { path: '/cadastromateriais', component: CadastroMateriais , meta: {
    requiresAuth: true,

  }},
  { path: '/consultamateriais', component: ConsultaMateriais , meta: {
    requiresAuth: true,

  }},
  { path: '/lotes1', component: Lotes1 , meta: {
    requiresAuth: false,

  }},
    { path: '/loteitem', component: LoteItem , meta: {
    requiresAuth: true,

  }},  
  { path: '/monitoramento', component: Monitoramento , meta: {
    requiresAuth: true,

  }},  
  { path: '/login', component: Login },
  {
    path: '*',
    redirect: '/login',
  }
]



export default routes;
</script>
