import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store(
    {
        state: {
            logado: false,
            login: "" , 
            nomeUsuario : "", 
            emailUsuario: "",
            nomeEmpresa: "", 
            codigoEmpresa: "", 
            tipoUsuario: "", 
            materiais: "",
            emb: [],
            escondeCampos: false,
            xEquipamento: "" , 
            xLote: "", 
            modoEdicao: false,
            xmateriais:[] , 
            loteInterno: 0, 

        }




    }
)