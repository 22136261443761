<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:;">Itens de Lotes</a>
          </li>
        </ul>
        <h1 class="page-header mb-0">Itens de Lotes</h1>
      </div>
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-12">
        <!-- BEGIN panel -->
        <panel title="Itens de Lotes">
          <div>
            <form class="row row-cols-lg-auto g-3 align-items-center">
              <vue-good-table
                class="w-100"
                :columns="columns"
                ref="teste"
                :rows="capa"
                :lineNumbers="false"
                :search-options="{
                  enabled: true,
                  placeholder: 'Pesquisar',
                }"
                :pagination-options="{ enabled: true, position: 'bottom' }"
                :selectOptions="{
                  enabled: false,
                  selectOnCheckboxOnly: false,
                  selectionInfoClass:
                    'alert alert-info m-b-0 no-rounded-corner',
                  selectionText: 'registros selecionados',
                  clearSelectionText: 'Limpar',
                }"
              >
                <div slot="selected-row-actions"></div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'after'">
                    <button
                      @click="
                        editRow(
                          props.row.codigo,
                          props.row.descricao,
                          props.row.embalagem
                        )
                      "
                    >
                      Editar
                    </button>
                    <button @click="deleteRow(props.row.codigo)">
                      Excluir
                    </button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </form>
          </div>
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
    </div>
  </div>
</template>



<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";

export default {
  name: "LotesCiclos",
  data() {
    return {
      capa: [],
      columns: [
        {
          label: "Data",
          field: "data_inclusao",
          type: "date",
          dateInputFormat: "yyyy-mm-dd",
          dateOutputFormat: "dd-mm-yyyy",
          width: "10%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Equipamento",
          field: "equipamento",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Lote",
          field: "lote",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Material",
          field: "descricao_material",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Quant",
          field: "quantidade",
          type: "number",
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Embalagem",
          field: "descricao_embalagem",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Validade",
          field: "data_validade",
          type: "date",
          dateInputFormat: "yyyy-mm-dd",
          dateOutputFormat: "dd-mm-yyyy",
          width: "10%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
    };
  },
  created() {
    AppOptions.appEmpty = false;
    this.carregaLote();
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    async carregaLote() {
      const response = await api.post("loteitem", {
        empresa: this.$store.state.codigoEmpresa,
      });

      console.log(response.data);

      if (response.status == 200) {
        this.capa = response.data;
      } else {
        this.capa = [];
      }
    },
  },
};
</script>