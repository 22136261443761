<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:;">Consulta Materiais</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-12">
        <!-- BEGIN panel -->
          <panel title="Materiais">
            <div>
              <form class="row row-cols-lg-auto g-3 align-items-center">
                <div class="w-100">
                  <label class="form-label w-100">Consulta Materiais</label>
                  <v-select
                    class="w-100"
                    :options="materiais"
                    label="descricao"
                    v-model="materialSelecionado"
                  ></v-select>
                </div>
                <div class="w-20">
                  <label class="form-label w-100">Quantidade</label>
                  <input
                    type="number"
                    class="form-control"
                    id="quantidade"
                    placeholder="Quantidade"
                    v-model="quantidade"
                  />
                </div>
                <div class="w-25">
                  <label class="form-label w-20">Embalagem</label>
                  <v-select
                    class="w-100"
                    v-model="embalagemSelecionado"
                    :options="embalagens"
                    :reduce="(embalagens) => embalagens.codigo"
                    label="descricao"
                  ></v-select>
                </div>
                <div class="w-20">
                  <label class="form-label w-100">Validade</label>
                  <input
                    type="date"
                    class="form-control"
                    id="validade"
                    v-model="validade"
                    oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  />
                </div>
                <div class="w-25">
                  <label class="form-label w-100">Origem</label>
                  <v-select
                    class="w-100"
                    :options="origem"
                    label="descricao"
                    v-model="origemSelecionada"
                    :reduce="(origem) => origem.codigo"
                  ></v-select>
                </div>
                <div class="w-75">
                  <label class="form-label w-100">Observação</label>
                  <input
                    type="text"
                    class="form-control"
                    id="observacao"
                    placeholder="Observação"
                    v-model="observacao"
                    oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  />
                </div>

                <div class="ms-auto">
                  <a
                    @click="validar"
                    class="btn btn-success btn-rounded px-4 rounded-pill"
                    ><i
                      class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"
                    ></i>
                    Adicionar Materiais</a
                  >
                </div>

                <vue-good-table
                  class="w-100"
                  :columns="columns"
                  ref="teste"
                  :rows="addMat"
                  :lineNumbers="true"
                  :search-options="{
                    enabled: true,
                    placeholder: 'Pesquisar',
                  }"
                  :pagination-options="{ enabled: true, position: 'bottom' }"
                  :selectOptions="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionInfoClass:
                      'alert alert-info m-b-0 no-rounded-corner',
                    selectionText: 'registros selecionados',
                    clearSelectionText: 'Limpar',
                  }"
                >
                  <div slot="selected-row-actions">
                    <button
                      @click="download"
                      class="btn btn-xs btn-primary me-2"
                    >
                      Imprimir Etiquetas
                    </button>
                    <button @click="notificacaoExcluirItem('danger', 'Atenção! Deseja excluir os itens selecionados?', 'Excluir Itens?')" class="btn btn-xs btn-danger">Excluir</button>
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'image'">
                      <img
                        v-bind:src="props.row.image"
                        class="rounded my-n1 mx-auto d-block"
                        width="26"
                      />
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
              </form>
            </div>
          </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-24">
        <div class="ms-auto">
          <a
            @click="
              swalNotificationCancel(
                'question',
                'Deseja Incluir o Lote?',
                'Incluir Lote?'
              )
            "
            class="btn btn-success btn-rounded px-4 rounded-pill"
            ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Criar
            Lote</a
          >

          <a
            @click="voltar()"
            class="btn btn-danger btn-rounded px-4 rounded-pill"
            ><i class="fa fa-lg me-2 ms-n2 text-success-900"></i> Cancelar</a
          >

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";
import FileSaver from "file-saver";

export default {
  name: "CriarLote",
  data() {
    return {
      desabilita: false,
      equipSelecionado: [],
      materialSelecionado: [],
      embalagemSelecionado: "",
      teste: [],
      lote: "",
      integrador: "",
      numeroBiologico: "",
      equipamentos: [],
      materiais: [],
      embalagens: [],
      addMat: [],
      quantidade: "",
      validade: "",
      origem: [
        { codigo: "P", descricao: "PROPRIO" },
        { codigo: "T", descricao: "TERCEIROS" },
      ],
      origemSelecionada: "",
      observacao: "",
      xcodigoEmbalagem: "",
      xdescEmbalagem: "",
      columns: [
        {
          label: "Material",
          field: "descMaterial",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Quant",
          field: "quantidade",
          type: "number",
          width: "5%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Emb",
          field: "descEmbalagem",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Validade",
          field: "validade",
          type: "date",
          dateInputFormat: "yyyy-mm-dd",
          dateOutputFormat: "dd-mm-yyyy",
          width: "10%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Origem",
          field: "codigoOrigem",
          //type: 'percentage',
          width: "5%",
          tdClass: "text-center valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
    };
  },
  created() {
    AppOptions.appEmpty = false;

    this.carregaEquipamento();
    this.carregaMateriais();
    this.carregaEmbalagem();
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    download() {
      console.log(this.$refs["teste"].selectedRows);

      let teste = "";
      let lok = false;

      for (var i = 0; i < this.$refs["teste"].selectedRows.length; i++) {
        lok = true;

        let a = this.$refs["teste"].selectedRows[i];
        // Iterate over numeric indexes from 0 to 5, as everyone expects.
        console.log(a.descMaterial);

        let current_datetime = new Date();
        current_datetime.setDate(current_datetime.getDate());

        //console.log(current_datetime);

        //current_datetime.setDate(current_datetime.getDate() + this.validade);

        let mes = current_datetime.getMonth() + 1;

        if (mes < 10) {
          mes = "0" + mes;
        }

        let dia = current_datetime.getDate();

        if (dia < 10) {
          dia = "0" + dia;
        }

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date =
          dia + "/" + mes + "/" + current_datetime.getFullYear();

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date2 =
          a.validade.substring(8, 10) +
          "/" +
          a.validade.substring(5, 7) +
          "/" +
          a.validade.substring(0, 4);

        //this.$refs['teste'].unselectAllInternal();

        teste += "N\r\n";
        teste += "D9\r\n";
        teste += "S3\r\n";
        teste += "JF\r\n";
        teste += "ZT\r\n";
        teste += "Q280, 24\r\n";
        teste += "q560\r\n";
        teste += `A20,15,0,3,1,1,N,"MAT: ` + a.descMaterial.trim() + `"\r\n`;
        teste +=
          `A20,35,0,3,1,1,N,"Esterilizacao: ` + formatted_date.trim() + `"\r\n`;
        teste +=
          `A20,55,0,3,1,1,N,"Validade: ` + formatted_date2.trim() + `"\r\n`;
        teste +=
          `A20,75,0,3,1,1,N,"Autoclave: ` + a.equipamento.trim() + `"\r\n`;
        teste += `A20,95,0,3,1,1,N,"Prog: ***"\r\n`;
        teste += `A20,115,0,3,1,1,N,"Lote: ` + a.lote.trim() + `"\r\n`;
        teste += `A20,135,0,3,1,1,N,"N ` + parseInt(a.quantidade) + `"\r\n`;
        teste +=
          `A20,155,0,3,1,1,N,"Resp: ` +
          this.$store.state.login.trim() +
          `"\r\n`;
        teste += `P` + parseInt(a.quantidade) + `\r\n`;
      }

      if (lok) {
        var blob = new Blob([teste], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "etiqueta.txt");

        this.$refs["teste"].unselectAllInternal();
      }
    },

    async incluirLote() {
      //console.log(this.$refs['teste'].selectedRows);

      //this.$refs['teste'].selectedRows
      //this.$refs['teste'].unselectAllInternal();

      if (this.addMat.length == 0) {
        this.swalNotification(
          "error",
          "Não é possível incluir lotes sem materais. Verifique!",
          "Atenção!"
        );
        return;
      }

      let current_datetime = new Date();
      current_datetime.setDate(current_datetime.getDate());

      //console.log(current_datetime);

      //current_datetime.setDate(current_datetime.getDate() + this.validade);

      let mes = current_datetime.getMonth() + 1;

      if (mes < 10) {
        mes = "0" + mes;
      }

      let dia = current_datetime.getDate();

      if (dia < 10) {
        dia = "0" + dia;
      }

      //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
      let formatted_date =
        current_datetime.getFullYear() + "-" + mes + "-" + dia;
      //console.log(formatted_date);

      let Lotes = {
        empresa: this.$store.state.codigoEmpresa,
        equipamento: this.equipSelecionado.codigo,
        lote: this.lote,
        integrador: this.integrador,
        numero_biologico: this.numeroBiologico,
        programa: "programa123",
        data: formatted_date,
        usuario: this.$store.state.login,
        itens: this.addMat,
      };

      const response = await api.post("inclotecapa", Lotes);

      if (response.status == 200) {
        //-this.embalagens = response.data;
        this.voltar();
        //console.log("inclui lote certo");
      } else {
        //console.log("erro inclusao lote");
      }
    },
    validar: function (e) {
      e.preventDefault();

      if (this.equipSelecionado == "") {
        this.swalNotification("error", "Informe o equipamento!", "Atenção!");
      } else if (this.lote == "") {
        this.swalNotification("error", "Informe o Lote!", "Atenção!");
      } else if (this.materialSelecionado == "") {
        this.swalNotification("error", "Informe o Material!", "Atenção!");
      } else if (this.quantidade == 0 || this.quantidade == "") {
        this.swalNotification("error", "Informe a Quantidade!", "Atenção!");
      } else if (this.embalagemSelecionado == "") {
        this.swalNotification("error", "Informe a Embalagem!", "Atenção!");
      } else if (this.validade == "") {
        this.swalNotification(
          "error",
          "Informe a Data de Validade!",
          "Atenção!"
        );
      } else {
        this.addMat.push({
          key: Date.now(),
          equipamento: this.equipSelecionado.codigo,
          lote: this.lote,
          codigoMaterial: this.materialSelecionado.codigo,
          descMaterial: this.materialSelecionado.descricao,
          quantidade: parseInt(this.quantidade),
          codigoEmbalagem: this.xcodigoEmbalagem,
          descEmbalagem: this.xdescEmbalagem,
          validade: this.validade,
          codigoOrigem: this.origemSelecionada,
          observacao: this.observacao,
        });

        //console.log("adicionou produtos");
        //console.log(this.addMat);

        this.materialSelecionado = "";
        this.validade = "";
        this.embalagemSelecionado = "";
        this.quantidade = "";
        this.observacao = "";
      }

      /*

      

      

      if (this.equipSelecionado == "") {
        this.swalNotification("error", "Informe o equipamento!", "Atenção!");
      } else if (this.lote == "") {
        this.swalNotification("error", "Informe o Lote!", "Atenção!");
      }*/
    },
    voltar() {
      this.$router.push({ path: "/lotesciclos" });
    },
    deleteItem() {
      //alert('entrou no delete - ' + key);

      let filtro = [];
      for (var i = 0; i < this.$refs["teste"].selectedRows.length; i++) {


        let a = this.$refs["teste"].selectedRows[i];

        filtro = this.addMat.filter((item) => {
          return item.key !== a.key;
        });
      }

      return (this.addMat = filtro);
    },
    async carregaEquipamento() {
      const response = await api.post("equipamentos", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.equipamentos = response.data;
      } else {
        this.equipamentos = [];
      }
    },
    async carregaMateriais() {
      const response = await api.post("materiais", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.materiais = response.data;
      } else {
        this.materiais = [];
      }
    },
    async carregaEmbalagem() {
      const response = await api.post("embalagem", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.embalagens = response.data;
      } else {
        this.embalagens = [];
      }
    },
    swalNotification(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: false,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
    swalNotificationCancel(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          this.incluirLote();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
    notificacaoExcluirItem(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          this.deleteItem();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
  },
  watch: {
    embalagemSelecionado: {
      deep: true,
      handler() {
        //console.log("embalagem selecionado");
        //console.log(this.embalagemSelecionado);

        //this.embalagemSelecionado = "2         ";

        //console.log(this.embalagemSelecionado);
        //console.log(this.embalagens[0].descricao);

        let filtro = this.embalagens.filter((codigo) => {
          return codigo.codigo == this.embalagemSelecionado;
        });

        //console.log("antes filtro");
        //console.log(filtro);

        //console.log("depois do filtro vamos la ");
        //console.log(filtro[0].codigo);
        //console.log(filtro[0].descricao);
        //console.log(filtro[0].validade);

        let current_datetime = new Date();
        current_datetime.setDate(
          current_datetime.getDate() + filtro[0].validade
        );

        //console.log(current_datetime);

        //current_datetime.setDate(current_datetime.getDate() + this.validade);

        let mes = current_datetime.getMonth() + 1;

        if (mes < 10) {
          mes = "0" + mes;
        }

        let dia = current_datetime.getDate();

        //console.log("dia????");
        //console.log(dia.toString);

        if (dia < 10) {
          dia = "0" + dia;
        }

        //let formatted_date = current_datetime.getDate() + "/" + mes + "/" + current_datetime.getFullYear();
        let formatted_date =
          current_datetime.getFullYear() + "-" + mes + "-" + dia;
        //console.log(formatted_date);

        //d.setDate(d.getDate() + days);

        this.xcodigoEmbalagem = filtro[0].codigo;
        this.xdescEmbalagem = filtro[0].descricao;

        this.validade = formatted_date;
      },
    },
    materialSelecionado: {
      deep: true,
      handler() {
        this.embalagemSelecionado = this.materialSelecionado.embalagem;
        this.origemSelecionada = "P";
      },
    },
    addMat: {
      deep: true,
      handler() {
        if (this.addMat.length > 0) {
          this.desabilita = true;
        } else {
          this.desabilita = false;
        }
      },
    },
  },
};
</script>