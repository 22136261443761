<template>
  <div>
    <!-- BEGIN breadcrumb -->

    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->

    <!-- END page-header -->

    <!-- BEGIN panel -->
    <!-- <panel title="Monitoramento"> -->

    <div class="row mb-3">
      <div class="col-xl-6" v-for="item in equipamentos" :key="item.codigo">
        <Caixa v-bind:equipamento="item.codigo.trim()" />
        
      </div>
    </div>
    <!-- </panel> -->
    <!-- END panel -->
  </div>
</template>


<script>
import Caixa from "../components/custom/Caixa";
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";

export default {
  name: "Monitoramento",
  components: {
    Caixa,
  },
  created() {
    AppOptions.appEmpty = false;
    this.carregaEquipamentos()
  },

  data() {
    return {
      nome: "teste rodrigo",
      equipamentos: null,
    };
  },
  methods: {
    async carregaEquipamentos() {
      const response = await api.post("equipamentospainel", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.equipamentos = response.data;
      } else {
        this.equipamentos = [];
      }
    },
  },
};
</script>