<template>
    <panel title="Lotes X Ciclos" class="h-600px">
        <iframe
          style="border: none; left: 0; width: 100%; height: 100%"
          src="http://127.0.0.1:8091/scriptcase/app/dataciclos2/blank_inicio/blank_inicio_teste.php?nmgp_outra_jan=true&nmgp_start=SC&2686"
        ></iframe>
    </panel>
</template>



<script>
import AppOptions from "../config/AppOptions.vue";

export default {
  name: "Lotes1",
  data() {
    return {};
  },
  created() {
    AppOptions.appEmpty = false;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    gerencial() {
      //window.open(`http://localhost:8081/?uid=${this.uid}`, '_blank')
      window.open(
        `https://dataciclos.com.br/data2/grid_dc_lote_capa/?x_empresa=3`,
        "_blank"
      );
      //"https://dataciclos.com.br/data2/grid_dc_lote_capa/?x_empresa=3"
    },
  },
};
</script>