<template>
  <!-- BEGIN login -->
  <div class="login login-v1">
    <!-- BEGIN login-container -->
    <div class="login-container">
      <!-- BEGIN login-header -->
      <div class="login-header">
        <div class="brand">
          <div class="d-flex align-items-center">
            <span class="logo"></span> <b>Data</b> Ciclos
          </div>
          <small>Alnath|MCA Hospitalar</small>
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <!-- END login-header -->

      <!-- BEGIN login-body -->
      <div class="login-body">
        <!-- BEGIN login-content -->
        <div class="login-content fs-13px">
          <form v-on:submit="valida" method="GET">
            <div class="form-floating mb-20px">
              <input
                type="text"
                class="form-control fs-13px h-45px"
                id="emailAddress"
                placeholder="Usuário"
                v-model="usuarioLogin"
              />
              <label for="emailAddress" class="d-flex align-items-center py-0"
                >Usuário</label
              >
            </div>
            <div class="form-floating mb-20px">
              <input
                type="password"
                class="form-control fs-13px h-45px"
                id="password"
                placeholder="Senha"
                v-model="usuarioSenha"
              />
              <label for="password" class="d-flex align-items-center py-0"
                >Senha</label
              >
            </div>
            <div class="form-check mb-20px">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="rememberMe"
              />
              <label class="form-check-label" for="rememberMe">
                Lembrar-me
              </label>
            </div>
            <div class="login-buttons">
              <button
                type="submit"
                class="btn h-45px btn-success d-block w-100 btn-lg"
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-body -->
    </div>
    <!-- END login-container -->
  </div>
  <!-- END login -->
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";

export default {
  name: "LoginPage",
  data() {
    return {
      nome: "",
      email: "",
      empresa: "",
      usuarioLogin: "",
      usuarioSenha: "",
    };
  },
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      //this.$store.state.logado = true;
      //console.log('validando login');
      //console.log(this.$store.state.logado);
      //this.$router.push({ path: '/'})
    },
    async carregaMateriais() {
      const response = await api.post("materiais", {
        empresa: this.$store.state.codigoEmpresa,
      });

      console.log("carrega materiais????????");

      if (response.status == 200) {
         this.$store.state.xmateriais = response.data;
      } else {
        this.$store.state.xmateriais = [];
      }
    },
    async valida(e) {
      e.preventDefault();
      /*    const response =  api.post("autentica", {
        usuario: "admin",
        senha: "admin",
      });*/

      console.log("antes do response");

      console.log("apiiiiiiiiiiiiiii");

      console.log(api);

      const response = await api.post("autentica", {
        usuario: this.usuarioLogin,
        senha: this.usuarioSenha,
      });

      console.log(response.data);

      this.nome = response.data.nome;
      this.email = response.data.email;
      this.empresa = response.data.empresa;

      if (response.status == 200) {
        this.$store.state.logado = true;
        this.$store.state.nomeEmpresa = response.data.nomeEmpresa;
        this.$store.state.codigoEmpresa = response.data.empresa;
        this.$store.state.tipoUsuario = response.data.tipo;
        this.$store.state.emailUsuario = response.data.email;
        this.$store.state.login = this.usuarioLogin;
        this.$store.state.nomeUsuario = response.data.nome;

        if (response.data.empresa.trim() == "3") {
          this.$store.state.expira = true;
        }else{
          this.$store.state.expira = false;
        }

        //this.$store.state.expira = false;






        


        if (this.$store.state.tipoUsuario == "E") {
          this.$store.state.escondeCampos = true;
        } else {
          this.$store.state.escondeCampos = false;
        }

        console.log("validando login");
        console.log(this.$store.state.logado);
        this.$router.push({ path: "/" });
      } else {
        this.$bvModal.hide("modalDialog");

        console.log("usuario nao encontrado");
        this.swalNotification("error");
      }

      //      console.log(response);
    },
    swalNotification(swalType) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: "Erro Login",
        text: "Usuário ou senha inválidos. Verifique!",
        type: swalType,
        showCancelButton: false,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
  },
};
</script>