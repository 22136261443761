var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('panel',{attrs:{"title":"Itens de Lotes"}},[_c('div',[_c('form',{staticClass:"row row-cols-lg-auto g-3 align-items-center"},[_c('vue-good-table',{ref:"teste",staticClass:"w-100",attrs:{"columns":_vm.columns,"rows":_vm.capa,"lineNumbers":false,"search-options":{
                enabled: true,
                placeholder: 'Pesquisar',
              },"pagination-options":{ enabled: true, position: 'bottom' },"selectOptions":{
                enabled: false,
                selectOnCheckboxOnly: false,
                selectionInfoClass:
                  'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'registros selecionados',
                clearSelectionText: 'Limpar',
              }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'after')?_c('span',[_c('button',{on:{"click":function($event){return _vm.editRow(
                        props.row.codigo,
                        props.row.descricao,
                        props.row.embalagem
                      )}}},[_vm._v(" Editar ")]),_c('button',{on:{"click":function($event){return _vm.deleteRow(props.row.codigo)}}},[_vm._v(" Excluir ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"})])],1)])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('div',[_c('ul',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item active"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Itens de Lotes")])])]),_c('h1',{staticClass:"page-header mb-0"},[_vm._v("Itens de Lotes")])])])}]

export { render, staticRenderFns }